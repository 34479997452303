<template>
  <div id="app1">
    <div class="header MT_3 PL_2 PB_4" @click="goBack">
      <img src="../assets/icon30.png" alt="">
      <span>企业季度三表</span>
    </div>
    <div  class="modeList" v-if="list.length > 0">
      <div class="modeItem MB_3" @click="goq3" v-for="(item,index) in list" :key="index">
        <div class="modeitemtitle">2022年四季度 <span style="font-size:1.5rem">企业三表</span> </div>
        <div class="modeitemcompany MTB_1">研耀(上海)信息科技有限公司</div>
        <div class="modeflex MTB_2">
          <div style="color:#888888"><van-icon  name="clock-o" /> 2022-12-22</div>
          <div class="modebtn">查看详情</div>
        </div>
      </div>
    </div>
    <!-- <van-empty v-else description="暂无数据" /> -->
    <img v-else src="../assets/d552389eed21bb0ecc914eb4af122a1.jpg" alt="">
    <van-dialog style="text-align:center" @confirm="confirm" v-model="show">
      <div class="P_6">
          请升级套餐，查看更多内容
      </div> 
    </van-dialog>
  </div> 
</template>
<script>
export default {
  data(){
    return {
      list:[],
      show:false
    }
  },
  mounted(){
    if(this.list.length == 0){
      this.show = true
    }
  },
  methods:{
    goq3(){
      this.$router.push("/quarterthreeTable")
    },
    goBack(){
      this.$router.go(-1)
    },
    confirm(){
      this.$router.go(-1)
    },
  }
}
</script>
<style scoped>
#app1{
  background-color: #F7F7F7;
  height:100vh;
}
</style>